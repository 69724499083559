import React from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'gatsby-plugin-react-i18next';

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Privacy Policy</title>
      </Helmet>
      <section>
        <Container>
          <h1>Privacy Policy</h1>
          <p>Rev. 1/1/2024: Exer Gale and Exer Gale (Demo) on Meta Quest: No data is
            retrieved, stored, or retained other than what the Meta Quest
            automatically collects as well as settings, scores, and other
            playerpref data that is only saved by the app, as applicable
            (settings, scores stay on your device and CHARK Studio never sees
            it). Contact <a href={'email:info@chark.io'}>info@chark.io</a> with
            any questions or request for data privacy and removal (no collected
            data to remove, but if there were this would be the contact) </p>
        </Container>
      </section>
    </>
  );
};

export default PrivacyPolicy;
